.App {
	height: 100%;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.08);
}

.bg_std {
  font-weight: 600;
  color: #ffffff !important;
  background-color: #505050 !important;
}
.bg_syn {
  font-weight: 600;
  color: #ffffff !important;
  background-color: #7350b3 !important;
}
.bg_ant {
  font-weight: 600;
  color: #ffffff !important;
  background-color: #dc6061 !important;
}

.natmal_grade {
	display: inline-block;
	position: relative;
	margin: 0 0 0 5px;
	height: 12px;
	vertical-align: text-top;
	background: url('/assets/imgs/grade.png');
	background-repeat: no-repeat;
	font-size: 1px;
	text-indent: -9999em;
}
.grade_1 {
	width: 36px;
}
.grade_2 {
	width: 24px;
}
.grade_3 {
	width: 12px;
}

/* 품사 */
.dic_detail_content .section_01 h4.dept01,
.dic_detail_content .section_02 h4.idiom01 {display: block; padding:20px 0 16px 0; font-size: 1.2em; font-weight: 600; color: #EA581F; line-height: 1;}  /* - - 18px / 15px - - */

/* 쓰임 */
.dic_detail_content .section_01 h5.dept02 {display: block; padding:20px 0 0 0; overflow: hidden; color: #505050;}
.dic_detail_content .section_01 h5.dept02 span.sequence {font-size: 1.250em; color: #EA581F; line-height: 1;}   /* - - 15px / 12px - - */
.dic_detail_content .section_01 h5.dept02 span.ptrn_pattern {font-size: .9em; color: #828282; line-height: 1; margin-right: 4px; margin-left: 4px;}  /* - - 15px / 23px - - */
.dic_detail_content .section_01 h5.dept02 span.ptrn_grammar {font-size: .9em; color: #828282; line-height: 1;}  /* - - 15px / 12px - - */
dl.def {margin-top: -8px; margin-bottom: 0; padding-bottom: 3px;}
dl.def dt {font-size:0.95em; padding-top:20px; line-height: 1.5; color: #141414;} /* - - 17px / 16px - - */
dl.def dt em {float: left; font-size: 0.938em; padding-top: 2px; margin-left: 4px;}    /* - - 15px / 16px - - */
dl.def dt span.title,
dl.def dt span.dfn_pattern {display: inline-block; margin-left: 5px;}
dl.def dt span.dfn_grammar {display: inline-block; margin-left: 5px; color: #828282;}
dl.def dd {margin-left: 8px;}
.section_01 ul.example {margin: 8px 0 0 0;} /* - - 예시 ul- - */
.section_01 ul.example li {margin-left: 2px; margin-top:2px; font-size: 0.938em; line-height: 1.5; color: #828282; }    /* - -  15px / 16px - - */
.section_01 ul.example li:first-child {margin-top: 0;}
.section_01 ul.example li strong {font-weight:bold;}
.dic_detail_content .section_01 p {padding-bottom: 3px;}
.dic_detail_content .section_01 p.def {margin:10px 0 0 0; line-height: 1.5; color: #141414;}

/* 표준국어 > .section_02 속담,관용구 */
.idm {}
.idm dt {margin-top:26px; padding-bottom: 20px;}
.idm dt span.title {font-size:1.067em; display: block; line-height: 1.5; color: #141414;}   /* - - - 17px / 16px - - */
.idm dd {overflow: hidden; margin: 2px 0;font-size: 0.938em; line-height: 1.5; color: #828282;}  /* - - 15px / 16px - - */
.idm dd span.sequence {font-size:1.067em; font-weight: bold; line-height: 1.5; color: #333333;}
.idm dd span.gubn {margin-top:3px;color: #999999;}
.idm dd ul.example,
.idm dd span.gubn ul.example {margin-top:3px;}
.idm dd ul.example li,
.idm dd span.gubn ul.example li {padding-left: 12px; margin-top:3px; color: #828282; }
.idm dd ul.example li:first-child,
.idm dd span.gubn ul.example li:first-child {margin-top: 0;}
.idm dd span.sequence + span.gubn ul.example li {padding-left: 22px; margin-top:3px; color: #828282; }
.idm dd span.sequence + ul.example li {padding-left: 22px; margin-top:3px; color: #828282; }

.ant-list-empty-text {
  background-color: #ffffff;
}
